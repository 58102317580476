export const LIST_OF_STATES = [
  { label: 'Alabama', fullName: 'alabama', value: 'AL' },
  { label: 'Alaska', fullName: 'alaska', value: 'AK' },
  { label: 'Arizona', fullName: 'arizona', value: 'AZ' },
  { label: 'Arkansas', fullName: 'arkansas', value: 'AR' },
  { label: 'California', fullName: 'california', value: 'CA' },
  { label: 'Colorado', fullName: 'colorado', value: 'CO' },
  { label: 'Connecticut', fullName: 'connecticut', value: 'CT' },
  { label: 'Delaware', fullName: 'delaware', value: 'DE' },
  {
    label: 'District Of Columbia',
    fullName: 'district of columbia',
    value: 'DC',
  },
  { label: 'Florida', fullName: 'florida', value: 'FL' },
  { label: 'Foreign', fullName: 'foreign', value: 'FG' },
  { label: 'Georgia', fullName: 'georgia', value: 'GA' },
  { label: 'Hawaii', fullName: 'hawaii', value: 'HI' },
  { label: 'Idaho', fullName: 'idaho', value: 'ID' },
  { label: 'Illinois', fullName: 'illinois', value: 'IL' },
  { label: 'Indiana', fullName: 'indiana', value: 'IN' },
  { label: 'Iowa', fullName: 'iowa', value: 'IA' },
  { label: 'Kansas', fullName: 'kansas', value: 'KS' },
  { label: 'Kentucky', fullName: 'kentucky', value: 'KY' },
  { label: 'Louisiana', fullName: 'louisiana', value: 'LA' },
  { label: 'Maine', fullName: 'maine', value: 'ME' },
  { label: 'Maryland', fullName: 'maryland', value: 'MD' },
  { label: 'Massachusetts', fullName: 'massachusetts', value: 'MA' },
  { label: 'Michigan', fullName: 'michigan', value: 'MI' },
  { label: 'Minnesota', fullName: 'minnesota', value: 'MN' },
  { label: 'Mississippi', fullName: 'mississippi', value: 'MS' },
  { label: 'Missouri', fullName: 'missouri', value: 'MO' },
  { label: 'Montana', fullName: 'montana', value: 'MT' },
  { label: 'Nebraska', fullName: 'nebraska', value: 'NE' },
  { label: 'Nevada', fullName: 'nevada', value: 'NV' },
  { label: 'New Hampshire', fullName: 'new hampshire', value: 'NH' },
  { label: 'New Jersey', fullName: 'new jersey', value: 'NJ' },
  { label: 'New Mexico', fullName: 'new mexico', value: 'NM' },
  { label: 'New York', fullName: 'new york', value: 'NY' },
  { label: 'North Carolina', fullName: 'north carolina', value: 'NC' },
  { label: 'North Dakota', fullName: 'north dakota', value: 'ND' },
  { label: 'Ohio', fullName: 'ohio', value: 'OH' },
  { label: 'Oklahoma', fullName: 'oklahoma', value: 'OK' },
  { label: 'Oregon', fullName: 'oregon', value: 'OR' },
  { label: 'Pennsylvania', fullName: 'pennsylvania', value: 'PA' },
  { label: 'Rhode Island', fullName: 'rhode island', value: 'RI' },
  { label: 'South Carolina', fullName: 'south carolina', value: 'SC' },
  { label: 'South Dakota', fullName: 'south dakota', value: 'SD' },
  { label: 'Tennessee', fullName: 'tennessee', value: 'TN' },
  { label: 'Texas', fullName: 'texas', value: 'TX' },
  { label: 'Utah', fullName: 'utah', value: 'UT' },
  { label: 'Vermont', fullName: 'vermont', value: 'VT' },
  { label: 'Virginia', fullName: 'virginia', value: 'VA' },
  { label: 'Washington', fullName: 'washington', value: 'WA' },
  { label: 'West Virginia', fullName: 'west virginia', value: 'WV' },
  { label: 'Wisconsin', fullName: 'wisconsin', value: 'WI' },
  { label: 'Wyoming', fullName: 'wyoming', value: 'WY' },
];
