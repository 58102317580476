import { useEffect } from 'react';

import { navigate } from 'gatsby';

interface Props {
  to: string;
}

const Redirect = ({ to }: Props) => {
  useEffect(() => {
    navigate(to);
  }, [to]);
  return null;
};

export default Redirect;
