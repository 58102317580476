import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { CommonDirectionEnum } from 'src/graphql-types/globalUstaTypes';
import { SORT_DIRECTION as CLASSIC_SORT_DIRECTION } from 'src/utils/constants/classic';

const getSortOptions = (sorts) => {
  return sorts
    ? {
        field: sorts[0].property,
        direction: sorts[0].sortDirection === 'ASCENDING' ? CommonDirectionEnum.ASC : CommonDirectionEnum.DESC,
      }
    : undefined;
};

export const mapToClassicSorts = (sorts: PropertySortInput[]) => {
  return sorts.map(({ property, sortDirection }) => ({
    Property: property,
    SortDirection: CLASSIC_SORT_DIRECTION[sortDirection],
  }));
};

export default getSortOptions;
