import React, { useCallback, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import { Trans, useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId, useOrgSlug } from 'src/apollo/local-state';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import FloatingNotification from 'src/components/floating-notification/floating-notification';
import Icon from 'src/components/icon/icon';
import { useDebounce } from 'src/components/membership/membership';
import ButtonModal from 'src/components/modal/modal';
import { FIND_SCHOOL_ORGANISATIONS, UNCLAIM_ORGANISATION } from 'src/components/organisations/organisations-queries';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import SuccessNotification from 'src/components/success-notification/success-notification';
import { useControlledQuery } from 'src/components/table-controls/table-controls';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { getEnvConfig } from 'src/config/config';
import {
  FindSchoolOrganisations,
  FindSchoolOrganisationsVariables,
  FindSchoolOrganisations_findOrganisations_results as SchoolsListItem,
} from 'src/graphql-types/FindSchoolOrganisations';
import {
  OrganisationClaimStatusEnum,
  OrganisationFilterOptionsInput,
  OrganisationTypeEnum,
} from 'src/graphql-types/globalUstaTypes';
import { UnclaimOrganisation, UnclaimOrganisationVariables } from 'src/graphql-types/UnclaimOrganisation';
import { formatDate } from 'src/utils/helper/membership';
import { capitalize } from 'src/utils/helper/rankings';
import getSortOptions from 'src/utils/helper/sortOptions';
import ROUTES from 'src/utils/routes';

import SchoolsFilter from './schools-filter';
import * as styles from './schools.module.less';

interface LabelOptions {
  city?: string;
  postalCode?: string;
  organisationId?: string;
}

export function constructLabel({ city, postalCode, organisationId }: LabelOptions): string {
  if (city && postalCode) {
    return `${city} - ${postalCode}`;
  }
  if (city) {
    return city;
  }
  if (postalCode) {
    return postalCode;
  }
  if (organisationId) {
    return organisationId;
  }
  return '';
}

const SchoolsList: React.FC = () => {
  const [selectedStatus, setSelectedStatus] = useState<OrganisationClaimStatusEnum | ''>('');
  const [selectedSchool, setSelectedSchool] = useState('');
  const [search, setSearch] = useState('');
  const [unclaimNotification, setUnclaimNotification] = useState('');
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const orgId = useOrgId();
  const { t } = useTranslation();
  const organisationSlug = useOrgSlug(orgId);
  const searchFilter = useDebounce(search, 500);

  const getSelectedFilters = useCallback(() => {
    const defaultFilters: OrganisationFilterOptionsInput = {
      organisationType: { eq: OrganisationTypeEnum.SCHOOL },
      parentOrganisationId: orgId,
    };
    return {
      ...defaultFilters,
      ...(selectedStatus ? { claimStatus: { eq: selectedStatus } } : {}),
      ...(searchFilter && { search: searchFilter }),
    };
  }, [orgId, searchFilter, selectedStatus]);

  const [unclaimOrganisation, { loading: unclaimLoading }] = useMutation<
    UnclaimOrganisation,
    UnclaimOrganisationVariables
  >(UNCLAIM_ORGANISATION, {
    client: meshGatewayClient,
  });

  const { data, loading, controlProps, refetch } = useControlledQuery<
    FindSchoolOrganisations,
    FindSchoolOrganisationsVariables & { offset: number; limit: number }
  >(FIND_SCHOOL_ORGANISATIONS, {
    client: meshGatewayClient,
    getTotalItems: (org: FindSchoolOrganisations) => org.findOrganisations.total ?? 0,
    notifyOnNetworkStatusChange: true,
    transformVariables: (v: any) => {
      const { limit, offset, sorts } = v;
      const filters = getSelectedFilters();
      const sortOptions = getSortOptions(sorts);

      return {
        pageArgs: { limit, skip: offset },
        filter: filters,
        ...(sortOptions && { sort: sortOptions }),
      };
    },
  });

  const schoolsList = useMemo(
    () =>
      data?.findOrganisations.results.map((org) => ({
        ...org,
        id: org.organisationId,
      })) ?? [],
    [data?.findOrganisations?.results],
  );

  const tableFilters = useMemo(getSelectedFilters, [getSelectedFilters]);

  const columns: DataCols<SchoolsListItem & { id: string }> = [
    {
      key: 'organisationName',
      title: t('name'),
      getValue: (org) => {
        return (
          <>
            <p className={styles.orgName}>{org.organisationName}</p>
            <small>
              {constructLabel({
                city: org.addresses?.[0]?.city ?? '',
                postalCode: org.addresses?.[0]?.postalCode ?? '',
                organisationId: org.organisationId,
              })}
            </small>
          </>
        );
      },
      sort: true,
    },
    {
      key: 'externalId',
      title: t('registry id'),
      getValue: (org) => org.externalId ?? '',
      sort: false,
    },
    {
      key: 'status',
      title: t('status'),
      getValue: ({ claimStatus, claimStatusUpdatedAt }) => {
        return (
          <>
            <p className={styles.orgName}>{capitalize(claimStatus)}</p>
            {claimStatusUpdatedAt && <small>{formatDate(claimStatusUpdatedAt, 'MM-DD-YYYY')}</small>}
          </>
        );
      },
      sort: false,
    },
    {
      key: 'admin',
      title: t('admin'),
      getValue: (org) =>
        org.contacts[0]?.standardGivenName
          ? `${org.contacts[0]?.standardGivenName} ${org.contacts[0]?.standardFamilyName}`
          : '',
      sort: false,
    },
    {
      key: 'email',
      title: t('email'),
      getValue: ({ inviteRecipientEmail, contacts }) => inviteRecipientEmail ?? contacts[0]?.email,
      sort: false,
    },
    {
      key: 'city',
      title: t('city'),
      getValue: (org) => org.addresses?.[0]?.city,
      sort: false,
    },
    {
      key: 'state',
      title: t('state'),
      getValue: (org) => org.addresses?.[0]?.state,
      sort: false,
    },
    {
      key: 'phone',
      title: t('phone'),
      getValue: (org) => org.contacts[0]?.phoneNumber,
      sort: false,
    },
    {
      key: 'actions',
      title: ' ',
      getValue: (org) => {
        const baseLeaguesRegistrationUrl = getEnvConfig().BASE_LEAGUE_REGISTRATION_URL;
        return (
          <div className={styles.actionsWrap}>
            {org.claimStatus === OrganisationClaimStatusEnum.CLAIMED ? (
              <a href="#" onClick={(e) => openRemoveModal(e, org.organisationId)}>
                {t('remove')}
              </a>
            ) : (
              <a href={`/organisations/manage-schools/invite-admin/${org.organisationId}`}>{t('invite')}</a>
            )}
            <a
              href={`${baseLeaguesRegistrationUrl}/state/${organisationSlug}/school/${org.organisationId}`}
              target="_blank"
              rel="noreferrer"
            >
              {t('view')}
            </a>
          </div>
        );
      },
      sort: false,
    },
  ];

  const openRemoveModal = (event: React.MouseEvent<HTMLAnchorElement>, schoolId: string) => {
    event.preventDefault();
    setRemoveModal(true);
    setSelectedSchool(schoolId);
  };

  const onRemoveAdmin = () => {
    unclaimOrganisation({
      variables: {
        organisationId: selectedSchool,
      },
    })
      .then(() => {
        refetch();
        setUnclaimNotification(t('admin removed'));
      })
      .catch(() => {
        setUnclaimNotification(t('admin remove error'));
      })
      .finally(() => {
        setRemoveModal(false);
      });
  };

  return (
    <PageMaxWidth>
      <Grid container>
        <PageHeader title={t('manage schools')} />
      </Grid>
      <Panel extendedPadding>
        <SchoolsFilter
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
          search={search}
          onSearch={setSearch}
        />
        {!loading && data?.findOrganisations.results.length === 0 ? (
          <div className={styles.emptyState}>
            <div>
              <Icon name={'schools'} />
            </div>
            <p>
              <Trans
                i18nKey="no schools"
                components={{
                  site: <a href={getEnvConfig().ORG_REQUEST_FORM_URL} target="_blank" rel="noreferrer" />,
                }}
              />
            </p>
          </div>
        ) : (
          <AdminTable
            data={schoolsList}
            loading={loading}
            columns={columns}
            controls={controlProps}
            filters={tableFilters}
          />
        )}
        <ButtonModal
          show={removeModal}
          openButton={{ hidden: true }}
          title="Remove Admin?"
          onVisibilityChange={setRemoveModal}
          actionButtons={[
            {
              id: 'remove',
              content: t('remove'),
              props: {
                type: 'submit',
                level: 'primary',
                onClick: onRemoveAdmin,
                loading: unclaimLoading,
                className: styles.removeButton,
              },
            },
          ]}
        >
          <p className={styles.noMargin}>{t('are you sure you want to remove')}</p>
          <p>{t('this will remove them as admin')}</p>
        </ButtonModal>
      </Panel>
      <SuccessNotification atUrl={ROUTES.MANAGE_SCHOOLS} hideAfterMs={5000} />
      {unclaimNotification && (
        <FloatingNotification message={unclaimNotification} onClose={() => setUnclaimNotification('')} />
      )}
      {}
    </PageMaxWidth>
  );
};

export default SchoolsList;
