import React from 'react';

import { useTranslation } from 'react-i18next';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Dropdown from 'src/components/dropdown/dropdown';
import TextInput from 'src/components/input/input';
import { OrganisationClaimStatusEnum } from 'src/graphql-types/globalUstaTypes';

import * as styles from './schools.module.less';

interface SchoolsFilterProps {
  selectedStatus: OrganisationClaimStatusEnum | '';
  setSelectedStatus: (status) => void;
  search: string;
  onSearch: (value) => void;
}
const SchoolsFilter: React.FC<SchoolsFilterProps> = ({ selectedStatus, setSelectedStatus, search, onSearch }) => {
  const { t } = useTranslation();
  return (
    <CustomGrid container spacing={{ margins: { sm: 'bottom' } }} className={styles.container}>
      <div className={styles.searchContainer}>
        <TextInput
          placeholder={t('search school or admin')}
          value={search}
          onChange={(t) => onSearch(t?.target.value)}
          inputProps={{
            ['data-testid']: 'schools-input-search',
          }}
          fullWidth
          disableUnderline
          outlined
          onClearClick={search ? () => onSearch('') : undefined}
        />
      </div>
      <Dropdown
        key="claimStatus"
        options={[
          { label: t('any status'), value: '' },
          { label: t('claimed'), value: OrganisationClaimStatusEnum.CLAIMED },
          {
            label: t('unclaimed'),
            value: OrganisationClaimStatusEnum.UNCLAIMED,
          },
          { label: t('invited'), value: OrganisationClaimStatusEnum.INVITED },
        ]}
        spacing={{ margins: { md: 'bottom', sm: 'left' } }}
        selected={selectedStatus}
        data-testid="claim-status-select"
        onSelect={(option) => setSelectedStatus(option.value)}
      />
    </CustomGrid>
  );
};

export default SchoolsFilter;
