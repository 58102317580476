export const WEEKDAY_TYPE_MAP = {
  [0]: 'sunday',
  [1]: 'monday',
  [2]: 'tuesday',
  [3]: 'wednesday',
  [4]: 'thursday',
  [5]: 'friday',
  [6]: 'saturday',
} as const;

export const SCHEME_COURSE_STATUS_MAP = {
  [0]: 'active',
  [1]: 'inactive',
  [2]: 'cancelled',
} as const;

export const EQUALITY_OPERATOR = {
  EQUALS: 0,
  NOT_EQUALS: 1,
  CONTAINS: 9,
} as const;

export const SORT_DIRECTION = {
  ASCENDING: 0,
  DESCENDING: 1,
} as const;

export const SCHEME_SUBCATEGORY = {
  GROUP_COACHING: 0,
  HOLIDAY_CAMPS: 1,
  PRIVATE_LESSONS: 2,
  EVENTS: 3,
} as const;

export type SchemeSubCategory = (typeof SCHEME_SUBCATEGORY)[keyof typeof SCHEME_SUBCATEGORY];
