import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { LabelVariety } from 'src/components/status-label/status-label';
import { GetMember_member } from 'src/graphql-types/memberships/GetMember';
import { GetMembership_membership_members_data } from 'src/graphql-types/memberships/GetMembership';
import { MemberStateEnum, TransactionSuccessEnum } from 'src/graphql-types/memberships/globalMembershipTypes';

import { LIST_OF_STATES } from './list-of-states';

interface Options {
  label?: string | null;
  value?: string | null;
}

export const getFirstOptionValue = (options: Options[] | undefined) => {
  if (!options || !options?.length) {
    return '';
  }

  return options[0].value;
};

export const formatDate = (date: any, format: string) => {
  if (date) {
    return dayjs(date).format(format);
  }

  return 'N/A';
};

export const firstCapital = (label: string) => {
  if (!label) return '';
  return (label.charAt(0).toUpperCase() + label.slice(1, label.length).toLowerCase()).replace(/[^0-9a-z]/gi, ' ');
};

export const parseSchoolName = (name: string) => name.replace(/ Men's Team| Womens's Team| Women's Team/gi, '');

// Removes division substring from conference name
export function parseConferenceName(name: string) {
  if (!name) {
    return '';
  }

  const [division, conference] = name.split(' - ');
  return conference;
}

export const getAbbrByStateName = (name: string) => {
  const state = LIST_OF_STATES.find((state) => {
    return state?.fullName.toUpperCase() === name.toUpperCase();
  })?.value;
  return state ? state : '';
};

type Field = 'status' | 'year in school' | 'collegiate division' | 'division';

export const prettifyValues = (field: Field, value: string): string => {
  const fieldMappings: { [key: string]: { [key: string]: string } } = {
    status: {
      'current junior player': 'junior player',
      'current college player': 'senior player',
      NA: 'n/a',
      'N/A': 'n/a',
    },
    'year in school': {
      'hs freshman': 'hs freshman',
      'hs sophomore': 'hs sophomore',
      'hs junior': 'hs junior',
      'hs senior': 'hs senior',
      'college freshman': 'hs freshman',
      'college sophomore': 'hs sophomore',
      'college junior': 'hs junior',
      'college senior': 'hs senior',
      NA: 'n/a',
      'N/A': 'n/a',
    },
    'collegiate division': {
      DIV_I: 'division I',
      DIV_II: 'division II',
      DIV_III: 'division III',
      NAIA: 'NAIA',
      JUCO: 'JUCO',
      NA: 'n/a',
      'N/A': 'n/a',
      'division i': 'division I',
      'division ii': 'division II',
      'division iii': 'division III',
      naia: 'NAIA',
      juco: 'JUCO',
    },
    division: {
      DIV_I: 'division I',
      DIV_II: 'division II',
      DIV_III: 'division III',
      NAIA: 'naia',
      JUCO: 'juco',
      NA: 'n/a',
    },
  };

  return fieldMappings[field]?.[value] || '';
};

export function useMemberStateOptions() {
  const { t } = useTranslation();

  return [
    {
      label: t('unknown'),
      value: 'UNKNOWN',
    },
    {
      label: t('active'),
      value: 'ACTIVE',
    },
    {
      label: t('pending'),
      value: 'PENDING',
    },
    {
      label: t('lapsed'),
      value: 'LAPSED',
    },
    {
      label: t('cancelled'),
      value: 'CANCELLED',
    },
    {
      label: t('refunded'),
      value: 'REFUNDED',
    },
  ];
}

export function pretifyDivision(divisionName: string) {
  const { t } = useTranslation();
  switch (divisionName) {
    case 'DIV_I':
      return t('division I');
    case 'DIV_II':
      return t('division II');
    case 'DIV_III':
      return t('division III');
    case 'NAIA':
      return t('naia');
    case 'JUCO':
      return t('juco');
    case 'NA':
      return t('n/a');
    default:
      return divisionName;
  }
}

// Sorts conferences in the following order: Div 1, Div 2, Div 3, NAIA, JUCO.
export function sortConfrences(a, z) {
  const JUNIOR_COLLEGE = 'Junior College';

  if (a?.label?.includes(JUNIOR_COLLEGE) !== z?.label?.includes(JUNIOR_COLLEGE)) {
    return a?.label?.includes(JUNIOR_COLLEGE) ? 1 : -1;
  }

  return a?.label > z?.label ? 1 : -1;
}

type MemberType = GetMembership_membership_members_data | GetMember_member;

export const getMemberStatusColor = (status: string): LabelVariety => {
  switch (status) {
    case MemberStateEnum.ACTIVE:
      return 'success';
    case MemberStateEnum.CANCELLED:
      return 'error';
    case MemberStateEnum.PENDING:
      return 'warning';
    case MemberStateEnum.REFUNDED:
      return 'neutral2';
    case MemberStateEnum.LAPSED:
      return 'neutral';
    default:
      return 'neutral';
  }
};

export function getMemberStatus(member: MemberType): MemberStateEnum {
  return member?.state ?? MemberStateEnum.UNKNOWN;
}

export const getPayStatusVariety = (status: string): LabelVariety => {
  switch (status) {
    case TransactionSuccessEnum.SUCCESS:
      return 'success';
    case TransactionSuccessEnum.CANCELLED:
      return 'error';
    case TransactionSuccessEnum.PENDING:
      return 'warning';
    case TransactionSuccessEnum.REFUNDED:
      return 'neutral2';
    default:
      return 'neutral';
  }
};

// the API sometimes returns an array with null
// so we need to filter that before we can compare
export const isSinglePurchaseType = (m: MemberType) => (m.organisation?.teamIds || []).filter(Boolean).length < 2;
