import React, { ReactNode } from 'react';

import cx from 'classnames';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';

import * as styles from './form-error-message.module.less';

interface Props extends SpacingProps {
  message?: string;
}

const FormErrorMessage: React.FC<Props> = ({ message, spacing, children }) => {
  const spacingClass = useSpacing(spacing);
  return <div className={cx(styles.errorMessage, spacingClass)}>{message ?? children}</div>;
};

export default FormErrorMessage;
